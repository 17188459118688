@font-face {
  font-family: 'Medium';
  src: local('Archivo-Medium'),
    url('./fonts/Archivo/Archivo-Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'Regular';
  src: local('Archivo-Regular'),
    url('./fonts/Archivo/Archivo-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'SemiBold';
  src: local('Archivo-SemiBold'),
    url('./fonts/Archivo/Archivo-SemiBold.ttf') format('truetype');
}
@font-face {
  font-family: 'Bold';
  src: local('Archivo-Bold'),
    url('./fonts/Archivo/Archivo-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'Light';
  src: local('Archivo-Light'),
    url('./fonts/Archivo/Archivo-Light.ttf') format('truetype');
}
@font-face {
  font-family: 'Italic';
  src: local('Archivo-Italic'),
    url('./fonts/Archivo/Archivo-Italic.ttf') format('truetype');
}
